.card-transition {
  cursor: pointer;
  transition: 0.25s;
}
.card-transition:hover {
  transform: scale(1.1);
}
.z-index {
  z-index: 3000 !important;
}
